import { AuthProvider } from 'Infrastructure/AppContext';
import Routes from 'Infrastructure/Routes';

const App = () => {
    const user = sessionStorage.getItem('user');

    return (
        <AuthProvider user={user}>
            <Routes />
        </AuthProvider>
    );
}
export default App;
