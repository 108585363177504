import { useAuth } from "Infrastructure/AppContext";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

const WechatLogin = () => {
    const { auth, provider } = useAuth();
    const history = useHistory();

    useEffect(() => {
        const wechatLogin = async () => {
            try {
                const loginState = await auth.getLoginState() ?? await provider.getRedirectResult();
                if (loginState) {
                    console.log(loginState);
                    history.push('/');
                }

            } catch (error) {
                console.log("error", error);
            }
        };

        wechatLogin();
    }, [auth, provider, history]);

    return (<></>);
};

export default WechatLogin;