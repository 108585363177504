import { useAuth } from "Infrastructure/AppContext";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import logo from 'Images/logo_108.png';
import './Login.scss';

const Login = () => {
    const { auth, provider } = useAuth();
    const history = useHistory();

    const query = new URLSearchParams(useLocation().search);
    const code = query.get("code");
    console.log(code);
    const aa = 'https://open.weixin.qq.com/connect/qrconnect?appid=wxe48c5f7815674ee0&redirect_uri=http://localhost:3000/wechatlogin&response_type=code&scope=snsapi_login&state=123123&style=white#wechat_redirect';

    useEffect(() => {
        const wechatLogin = async () => {
            try {
                const loginState = await auth.getLoginState() ?? await provider.getRedirectResult();
                if (loginState) {
                    console.log(loginState);
                    history.push('/');
                }

            } catch (error) {
                console.log("error", error);
            }
        };

        wechatLogin();
    }, [auth, history, provider]);

    const wechatLogin = async () => {
        provider.signInWithRedirect();
    }

    const login = async () => {
        window.location.href = aa;
    }

    return (
        <div className="container">
            <div className="row justify-content-center auth-wrapper align-items-center">
                <div className="col-md-6">
                    <div className="authincation-content">
                        <div className="row no-gutters">
                            <div className="col-xl-12">
                                <div className="auth-form">
                                    <div className="text-center mb-3">
                                        <a href="index.html"><img src={logo} alt="" /></a>
                                    </div>

                                    <div className="form-row justify-content-center mt-4 mb-2">
                                        <iframe className="impowerBox" src={aa} title='ss' frameBorder={0} allowTransparency scrolling='no' height={400} width={300}></iframe>
                                    </div>
                                    <div className="text-center">
                                        <button onClick={() => login()} className="btn bg-white text-primary btn-block">Sign Me In (jump to wechatlogin page)</button>
                                        <button onClick={() => wechatLogin()} className="btn bg-white text-primary btn-block">Sign In (redirect same page)</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Login;