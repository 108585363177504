import { useEffect, useState } from 'react';
import { useAuth } from 'Infrastructure/AppContext';
import logo from 'Images/logo.png';
import { useHistory } from 'react-router-dom';

interface IUser {
    avatarUrl: string;
    city: string;
    country: string;
    gender: number;
    language: string;
    nickName: string;
    userId: string;
}

const Users = () => {
    const [users, setUsers] = useState<IUser[]>([]);
    const history = useHistory();
    const { database, auth } = useAuth();
    const currentUser = auth.currentUser;
    console.log(currentUser);

    useEffect(() => {
        const fetchData = async () => {
            try {

                const { data } = await database.collection('Users').limit(20).get();
                setUsers(data);
                console.log(data);

            } catch (error) {
                console.log("error", error);
            }
        };

        fetchData();
    }, [database]);

    const signOut = async () => {
        await auth.signOut();
        history.push('/login');
    }

    return (
        <div id="main-wrapper" className='show'>

            <div className="nav-header">
                <a href="index.html" className="brand-logo">
                    <img className="logo-abbr" src={logo} alt="logo" />
                    <img className="logo-compact" src="./images/logo-text.png" alt="" />
                    <img className="brand-title" src="./images/logo-text.png" alt="" />
                </a>

                <div className="nav-control">
                    <div className="hamburger">
                        <span className="line" /><span className="line" /><span className="line" />
                    </div>
                </div>
            </div>

            <div className="header">
                <div className="header-content">
                    <nav className="navbar navbar-expand">
                        <div className="collapse navbar-collapse justify-content-between">
                            <div className="header-left">
                                <div className="dashboard_bar">
                                    Users
                                </div>
                            </div>
                            <ul className="navbar-nav header-right">
                                <li className="nav-item dropdown header-profile">
                                    <a className="nav-link" href="/" role="button" data-toggle="dropdown">
                                        <img src={currentUser.avatarUrl} width="20" alt="" />
                                        <div className="header-info">
                                            <span className="text-black"><strong>{currentUser.nickName}</strong></span>
                                            <p className="fs-12 mb-0">Super Admin</p>
                                        </div>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a href="./app-profile.html" className="dropdown-item ai-icon">
                                            <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                            <span className="ml-2">Profile </span>
                                        </a>
                                        <a href="./email-inbox.html" className="dropdown-item ai-icon">
                                            <svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" className="text-success" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                            <span className="ml-2">Inbox </span>
                                        </a>
                                        <a href="/" onClick={() => signOut()} className="dropdown-item ai-icon">
                                            <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" className="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                            <span className="ml-2">Logout </span>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>

            <div className="deznav">
                <div className="deznav-scroll">
                    <ul className="metismenu" id="menu">
                        <li><a className="has-arrow ai-icon mm-active" href="/#" aria-expanded="true">
                            <i className="flaticon-381-networking"></i>
                            <span className="nav-text">Dashboard</span>
                        </a>
                            <ul aria-expanded="true" role='link' className="mm-collapse mm-show">
                                <li className="mm-active"><a className="mm-active" href="/">Users</a></li>
                                <li><a href="workoutplan.html">Workout Plan</a></li>
                                <li><a href="distance-map.html">Distance Map</a></li>
                                <li><a href="food-menu.html">Diet Food Menu</a></li>
                                <li><a href="personal-record.html">Personal Record</a></li>
                            </ul>
                        </li>
                        <li><a className="has-arrow ai-icon" href="/#" aria-expanded="false">
                            <i className="flaticon-381-television"></i>
                            <span className="nav-text">Apps</span>
                        </a>
                            <ul aria-expanded="false" role='link'>
                                <li><a href="./app-profile.html">Profile</a></li>
                                <li><a href="./app-calender.html">Calendar</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="content-body">
                <div className="container-fluid">
                    <div className="page-titles">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Dashboard</a></li>
                            <li className="breadcrumb-item active"><a href="/">Users</a></li>
                        </ol>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-responsive-lg mb-0 table-striped">
                                            <thead>
                                                <tr>
                                                    <th className="">
                                                        <div className="custom-control custom-checkbox mx-2">
                                                            <input type="checkbox" className="custom-control-input" id="checkAll" />
                                                            <label className="custom-control-label" htmlFor="checkAll" />
                                                        </div>
                                                    </th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th className="pl-5 width200">Billing Address
                                                    </th>
                                                    <th>Joined</th>
                                                    <th />
                                                </tr>
                                            </thead>
                                            <tbody id="customers">
                                                {users.map(user => <tr key={user.userId} className="btn-reveal-trigger">
                                                    <td>
                                                        <div className="custom-control custom-checkbox mx-2">
                                                            <input type="checkbox" className="custom-control-input" id="checkbox1" />
                                                            <label className="custom-control-label" htmlFor="checkbox1" />
                                                        </div>
                                                    </td>
                                                    <td className="py-3">
                                                        <a href="/">
                                                            <div className="media d-flex align-items-center">
                                                                <div className="avatar avatar-xl mr-2">
                                                                    <div className=""><img className="rounded-circle img-fluid"
                                                                        src={user.avatarUrl} width="50" alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="media-body">
                                                                    <h5 className="mb-0 fs--1">{user.nickName}</h5>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </td>
                                                    <td className="py-2"><a
                                                        href="mailto:ricky@example.com">info@example.com</a></td>
                                                    <td className="py-2"> <a href="tel:2012001851">(201) 200-1851</a></td>
                                                    <td className="py-2 pl-5 wspace-no">2392 Main Avenue, Penasauka</td>
                                                    <td className="py-2">30/03/2018</td>
                                                    <td className="py-2 text-right">
                                                        <div className="dropdown"><button className="btn btn-primary tp-btn-light sharp" type="button" data-toggle="dropdown"><span className="fs--1">
                                                            {/* <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><rect x="0" y="0" width="24" height="24"></rect><circle fill="#000000" cx="5" cy="12" r="2"></circle><circle fill="#000000" cx="12" cy="12" r="2"></circle><circle fill="#000000" cx="19" cy="12" r="2"></circle></g></svg> */}
                                                        </span></button>
                                                            <div className="dropdown-menu dropdown-menu-right border py-0">
                                                                <div className="py-2"><a className="dropdown-item" href="#!">Edit</a><a className="dropdown-item text-danger" href="#!">Delete</a></div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer">
                <div className="copyright">
                    <p>Copyright © Designed &amp; Developed by <a rel="noreferrer" href="http://www.bootstrapmb.com" target="_blank">DexignZone</a> 2020</p>
                </div>
            </div>
        </div>
    );
};
export default Users;


