import React from 'react';
import { getCloudBase } from './getCloudBase';

const AuthContext = React.createContext<any>(null);

// wrapper for the provider
export const AuthProvider = ({ user, children }) => {
    const { auth, database, provider } = getCloudBase();

    return (
        <AuthContext.Provider value={{ auth, database, provider }}>
            {children}
        </AuthContext.Provider>
    )
};

// custom hook
export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('auth hook can only be used in a component or hook that has been wrapped with a AuthProvider.');
    }
    return context;
}