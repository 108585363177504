import { useAuth } from "Infrastructure/AppContext";
import Login from "Pages/Login";
import Users from "Pages/Users";
import WechatLogin from "Pages/WechatLogin";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

const Routes = () => {
    return (
        <BrowserRouter>
            <Switch>
                {/* Login and Sign Up not a protected route */}
                <Route path="/login" exact component={Login} />
                <Route path="/wechatlogin" exact component={WechatLogin} />

                {/* Protected routes need authentication */}
                <ProtectedRoute path="/" exact>
                    <Users />
                </ProtectedRoute>

                <Route component={() => <>404</>} />
            </Switch>
        </BrowserRouter>
    );
}

const ProtectedRoute = ({ children, ...rest }) => {
    const { auth } = useAuth();

    return (
        <Route
            {...rest}
            render={({ location }) => auth.currentUser ? children : (<Redirect to={{ pathname: '/login', state: { from: location } }} />)}
        />
    );
}

export default Routes;